// use require() so that $ and jQuery can be shared globally
// https://stackoverflow.com/questions/47968529/how-do-i-use-jquery-and-jquery-ui-with-parcel-bundler

window.$ = window.jQuery = require('/vendor/components/jquery/dist/jquery');

require('/vendor/components/Buttons/js/buttons');
require('/vendor/components/mediaelement/build/mediaelement-and-player');

require('./live.js');
require('./site.js');
require('./tabs.js');

import './timeJump.js';
