window.RebuildTabs = class RebuildTabs {
  constructor(tabsContainer) {
    this.tabs = $(tabsContainer).find("a[href^=#]");
    this.tabs.on('click', this.showHidePanes.bind(this));
    this.doInitialHiding();
    let active = this.tabs.filter(".active").first();
    if (active.length === 0) {
      this.tabs.first().addClass('active');
    }

    let hash = $(`a[href$=\"${window.location.hash}_\"]`);
    if (window.location.hash.length && hash.length) {
      hash.click();
    } else {
      active.click();
    }
  }

  doInitialHiding() {
    return this.getPanes().hide();
  }

  showHidePanes(event) {
    // setup
    event.preventDefault();
    this.tabs.removeClass('active');
    let target = $(event.target).closest('a');
    target.addClass('active');

    let href = target.attr('href');
    this.getPanes().hide();
    $(href).show();

    if (typeof event.which !== 'undefined') {
      href = href.replace(/_$/, '');
      return window.history.pushState({}, href, href);
    }
  }

  getPanes() {
    let ids = [];
    this.tabs.each(function(i, e) {
      return ids.push($(e).attr('href'));
    });
    return $(ids.join(', '));
  }
};
