$(function($){
  var playing = false;

  var meify = function(jq, features) {
    // 0..9 to jump to 0%, 10%...
    const length = 10;
    let keyActions = Array.from({ length }, function(_, i) {
      return {
        keys: [48+i],
        action: function(player, media) {
          if (!isNaN(media.duration) && media.duration > 0) {
            media.setCurrentTime(media.duration * 0.1 * i);
          }
        },
      }
    });
    jq.mediaelementplayer({
      startVolume: 0.8,
      loop: false,
      enableAutosize: true,
      features: features,
      iPadUseNativeControls: false,
      iPhoneUseNativeControls: false,
      AndroidUseNativeControls: false,
      alwaysShowHours: false,
      showTimecodeFrameCount: false,
      framesPerSecond: 25,
      enableKeyboard: true,
      pauseOtherPlayers: true,
      pluginPath: '/vendor/components/mediaelement/build/',
      plugins: ['flash','silverlight'],
      defaultSeekBackwardInterval: function(media) { return 15 },
      defaultSeekForwardInterval: function(media) { return 30 },
      keyActions: mejs.MepDefaults.keyActions.concat(keyActions),
      success: function(media, dom) {
        $(media).on('play playing', function(){ playing = true });
        $(media).on('pause ended', function() { playing = false });
      }
    });
  };

  meify($('.player-live audio'), ['playpause', 'current', 'volume']);
  meify($('.player-episode audio'), ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'speed']);

  $(window).on('beforeunload', function(){
    if (playing) {
      return "Audio is playing in background."
    }
  });

  $(".post a").not(".internal").each(function(i, el) { $(el).attr('target', '_blank') });

  var container = $('#tabs')
  if (container.length > 0) {
    new RebuildTabs(container);
  }

  $(".mail").each(function(i, el) {
    var _this = $(this);
    var address = _this.data('local') + '@' + _this.data('domain');
    var mailto = "mailto:" + address;
    if (_this.data('subject')) {
      mailto += "?subject=" + encodeURIComponent(_this.data('subject'));
    }
    $(this).append($("<a>").attr("href", "#").text(address).hover(function() { $(this).attr("href", mailto) }));
  });

  $('.paginated').on('click', 'a.next', function(ev) {
    var _this = $(this);
    $.get(_this.attr('href'), null, function(data, status) {
      if (status == 'success') {
        _this.parent().remove();
        $($.parseHTML(data)).find('.paginated').children().appendTo($('.paginated'));
      }
    });
    ev.preventDefault();
  });

  if (location.search.indexOf('?l=en') == 0) {
    $('.ja').hide();
  } else {
    $('.en').hide();
  }

  $('a.push').on('click', function(ev) {
    ev.preventDefault();
    if (location.protocol == 'https:') {
      OneSignal.push(function(){ OneSignal.registerForPushNotifications() });
    } else {
      var dest = new URL('#push', location.href);
      dest.protocol = 'https:';
      location.href = dest.href;
    }
  });
});
