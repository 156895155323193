import moment from "/vendor/components/moment/moment";

$(function() {
  // Run on the live page only
  if ($("#streaming-schedule").length == 0) return

  $('.youtube-player').hide();

  var showYouTubeEmbed = function(id) {
    $('#youtube-embed').attr('src', 'https://www.youtube.com/embed/' + id + '?autoplay=1');
    $('#youtube-chat').attr('src', 'https://www.youtube.com/live_chat?v=' + id + '&embed_domain=rebuild.fm');

    $('.youtube-player').show();
  }

  var handleYouTube = function(data, status, xhr) {
    var title = $('entry title', data).first().text();
    if (title.match(/Rebuild.*[Ll]ive/)) {
      var id = $('entry id', data).first().text().match(/yt:video:(.*)/)[1];
      if (id) {
        showYouTubeEmbed(id);
      }
    }
  }
    
  var timezoneName = function(local) {
    var t = (new Date).toTimeString();
    var m = t.match(/\(([A-Z]+)\)/);
    return m ? m[1] : local.format("[GMT]ZZ")
  }

  var markupTwitterUser = function(text) {
    return text.replace(/@([0-9a-zA-Z_]+)/g, function(match, user){ return '<a href="https://twitter.com/intent/user?screen_name=' + user + '">@' + user + '</a>' });
  }

  var displayEvents = function(data, status, xhr) {
    var videoId;
    var items = data.items;
    if (items.length == 0) {
      $(".event-date").text("未定");
    } else {
      var event = items[0];

      var start = moment(event.start.dateTime);
      var dur = moment.duration(start - moment());
      var local = start.local();
      var jst = start.clone().utc().add(9, 'hours');

      var description = event.description || '';
      description = description.replace(
        /\n*\s*https:\/\/www.youtube.com\/watch\?v=(.*)/,
        function(m, v) { videoId = v; return '' },
      );

      $(".event-title").html(markupTwitterUser(description));
      $(".event-date").append(
        $('<a target="_blank">')
          .text( local.format("M/D (ddd) H:mm ") + timezoneName(local) )
          .attr('title', jst.format("M/D (ddd) H:mm [JST]") )
          .attr('href', "https://www.timeanddate.com/worldclock/fixedtime.html?msg=" + encodeURIComponent(event.summary) + "&iso=" + jst.format("YYYYMMDDTHHmm") + "&p1=248&ah=2")
      );
      if (dur.asMinutes() <= 30)
        $("audio").attr('preload', 'auto').attr('autoplay', 'autoplay');
    }

    if (videoId) {
      // override video ID from gcal event description
      showYouTubeEmbed(videoId)
    } else {
      $.ajax({
        url: "https://feeds.rebuild.fm/youtube",
        success: handleYouTube
      })
    }
  }

  var start = moment().seconds(0).milliseconds(0).subtract(2, 'hours').toISOString();
  var key = "AIzaSyBHVVjLhnwNkTttKRh_vLBLERJViqP2lZw";
  $.ajax({
    url: "https://feeds.rebuild.fm/gcal?orderBy=startTime&singleEvents=true&timeMin=" + start + "&key=" + key,
    success: displayEvents
  });
});
